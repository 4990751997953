
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            desktopBackground: 'cms/desktopBackground',
            mobileBackground: 'cms/mobileBackground',
        }),
    },
}
